import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _377bafe4 = () => interopDefault(import('../pages/nieuws/index.vue' /* webpackChunkName: "pages/nieuws/index" */))
const _3c63fc41 = () => interopDefault(import('../pages/zoek-resultaten.vue' /* webpackChunkName: "pages/zoek-resultaten" */))
const _5fcca7b9 = () => interopDefault(import('../pages/groene-norm/team-groene-norm.vue' /* webpackChunkName: "pages/groene-norm/team-groene-norm" */))
const _35c50e9c = () => interopDefault(import('../pages/nieuws/_slug.vue' /* webpackChunkName: "pages/nieuws/_slug" */))
const _378fea30 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6db53580 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/nieuws",
    component: _377bafe4,
    name: "nieuws"
  }, {
    path: "/zoek-resultaten",
    component: _3c63fc41,
    name: "zoek-resultaten"
  }, {
    path: "/groene-norm/team-groene-norm",
    component: _5fcca7b9,
    name: "groene-norm-team-groene-norm"
  }, {
    path: "/nieuws/:slug",
    component: _35c50e9c,
    name: "nieuws-slug"
  }, {
    path: "/",
    component: _378fea30,
    name: "index"
  }, {
    path: "/*",
    component: _6db53580,
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
